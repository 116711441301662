
import React from 'react'
import {Helmet} from 'react-helmet'
import {withPrefix} from 'gatsby'
// import 'set-iframe-height/dist/set-iframe-height-parent-min'
const isBrowser = typeof windows !=='undefined'
class Parent extends React.Component{
  componentDidMount(){
    if(isBrowser){

      let data = window.addEventListener('setIframeHeight', this.onSetIframeHeight);
    
      console.log("data ", data );
      
      window.addEventListener('message', this.onMessage);
    }

  }

  componentDidUpdate(){
    if(isBrowser){

      let data = window.addEventListener('setIframeHeight', this.onSetIframeHeight);
    
      console.log("data ", data );
      
      window.addEventListener('message', this.onMessage);
    }

  }



  polyFillNodeListForEach =()=> {
    // Polyfill for Browsers not supporting NodeList.forEach
    if(isBrowser){

      if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
          thisArg = thisArg || window;
          for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
          }
        };
      }
    }
  }

  onMessage = (e) => {
    var item = e.item;
    if (typeof item === 'string' && item.indexOf('::')) {
      var d = item.split('::');
      if (d.length === 2 && d[0] === 'setIframeHeight') {
        var params = JSON.parse(d[1]);
        this.setHeight(params);
      }
    }
  }
  triggerCustomEvent = (element, eventName, eventData)=> {
    if(isBrowser){

      var e = document.createEvent('CustomEvent');
      e.initCustomEvent(eventName, true, true, eventData);
      element.dispatchEvent(e);
    }
  }
 
   setHeight = (data) => {
     if(isBrowser){

       data.height = parseInt(data.height, 10);
       this.triggerCustomEvent(window, 'setIframeHeight', data);
     }
  }
  


   absolutizeUrl = (url)=> {
    if(isBrowser){

      if (url.indexOf('/') === 0) {
        url = this.getHostForUrl(document.location.href) + url;
      }
  
      return url;
    }
  }

   getHostForUrl = (url)=> {
    var matches = url.match(/https?:\/\/.[^/]+/) || [];
    return matches[0];
  }

   normalizeUrl = ( url) => {
    return url.replace(/^https?:\/\//, '//');
  }
   


  /************** PRIVATE VARS AND FUNCTIONS **************/

   findIframeBySrc =(src) =>{
    if(isBrowser){
    var bestMatchingIframe = null;

    document.querySelectorAll('iframe').forEach(function (iframe) {
      var iframeSrc = iframe.dataset.iframeAutoHeightCurrentSrc || iframe.src;
      if (iframeSrc) {
        iframeSrc = this.absolutizeUrl(iframeSrc);
        if (iframeSrc === src) {
          bestMatchingIframe = iframe;

          return false; //break loop
        }
      }
    });

    return bestMatchingIframe;
  }
   }
   findIframeById = (iframeId) => {
    if(isBrowser){
    var bestMatchingIframe = null;

    document.querySelectorAll('iframe').forEach(function (iframe) {
      if (!bestMatchingIframe && iframe.dataset.setIframeHeightId === iframeId) {
        bestMatchingIframe = iframe;
      }
    });

    return bestMatchingIframe;
  }
}
   onSetIframeHeight = (e) =>  {
    if(isBrowser){
    let lastHeights = {};
    var data = e.detail;
    var iframe;

    iframe = this.findIframeById(data.iframeId);

    if (!iframe) {
      iframe = this.findIframeBySrc(data.iframeSrc);
    }

    if (!iframe && data.iframeReferrer) {
      iframe = this.findIframeBySrc(data.iframeReferrer);
    }

    if (!iframe) {
      var enhancedIframes = document.querySelectorAll('iframe[data-set-iframe-height-id]');
      if (enhancedIframes.length ===1) {
        iframe = enhancedIframes[0];
      }
    }

    if (iframe) {
      iframe.dataset.setIframeHeightId = data.iframeId;

      iframe.style.height = data.height + 'px';

      var urlHasChanged = this.normalizeUrl(iframe.dataset.iframeAutoHeightCurrentSrc || iframe.src) !== this.normalizeUrl(data.iframeSrc);

      iframe.dataset.iframeAutoHeightCurrentSrc = data.iframeSrc;

      var lastHeight = lastHeights[data.iframeId];
      var height = data.height;
      if (lastHeight === undefined) {
        this.triggerCustomEvent(window, 'setIframeHeight:determined', data);
      } else if (lastHeight > height) {
        this.triggerCustomEvent(window, 'setIframeHeight:shrinked', data);
      } else if (lastHeight < height) {
        this.triggerCustomEvent(window, 'setIframeHeight:enlarged', data);
      }

      lastHeights[data.iframeId] = height;

      if (window.history.replaceState && iframe.dataset.iframeAutoHeightDeepLinkPattern && urlHasChanged) {
        var parentUrl = this.absolutizeUrl(iframe.dataset.iframeAutoHeightDeepLinkPattern.replace(/%deepLinkIframeSrc%/, encodeURIComponent(data.iframeSrc)));
        if (this.normalizeUrl(document.location.href) !== this.normalizeUrl(parentUrl)) {
          window.history.replaceState({}, '', parentUrl);
          this.triggerCustomEvent(window, 'setIframeHeight:deepLink:changed', {
            childUrl: data.iframeSrc,
            parentUrl: parentUrl
          });
        }

        if (iframe.contentWindow && iframe.contentWindow.postMessage) {
          iframe.contentWindow.this.postMessage('setIframeHeight:deepLink:changed::{ "parentUrl": "' + parentUrl + '", "childUrl": "' + data.iframeSrc + '"}', '*');
        }
      }
    }
  }
   }
  
  render(){
    this.polyFillNodeListForEach();
    return(
      <>
      <Helmet>
        <script src={withPrefix('set-iframe-height-parent-min.js')}></script>
      </Helmet>
      <iframe
      src="https://paycare.com/crypto/#/exchange/btc-to-eth"
      id="myIframe"
      style={{
        minWidth: 370,
        //            maxWidth: 700,
        zIndex: "99999",
                    width: "100%",
        overflow: "auto",
        border: "none"
      }}
      title="ifram-title"

      scrolling="no"
    />
</>
    )
  }
}
// goto desktop please ok paste file in static folder done
export default Parent







